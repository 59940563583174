<template>
    <main>
        <div class="hero" style="background-image: linear-gradient(rgba(0 0 0 / 0.3), rgba(0 0 0 / 0.3)), url('images/cb_inner_pages_default_bg-min.jpg');">
           <div class="container container--xs text-center">
                <h1>Mensaje principal de imagen de promoción</h1>
                <p>Subtítulo sumilla o texto editable</p>
           </div>
        </div>
        <section class="section pt-0">
            <div class="container">
                <p class="breadcrumb"><a href="/"><span class="icon-home-outline"></span></a> / <span>Términos y condiciones</span></p>
                <h2><a href="#" @click="router.go(-1)"><img src="images/arrowleft.svg" alt="Club de Sastreria Barrington"></a> Términos y condiciones</h2>
                <div class="grid grid--two-columns grid--gap-md py-lg pt-0">
                    <p>Ingrese texto aquí...</p>
                </div>
                
            </div>
        </section>
    </main>
</template>


<script>
import { ref, computed } from "vue";
import router from "@/router"
import API from "@/api";

export default {
    name: 'TermsAndConditions',
    components: {
        
    },

    setup(props, context) {
        return { router }
    }

    
}
</script>